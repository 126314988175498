import { IconProps } from '@/static/types';

function Check({ className }: IconProps) {
  return (
    <svg
      className={className}
      width='16'
      height='24'
      viewBox='0 0 16 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3538 8.68684L6.66687 16.3737L1.97998 11.6868L2.68709 10.9797L6.66687 14.9595L13.6466 7.97974L14.3538 8.68684Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default Check;
