import { IconProps } from '@/static/types';

function WhiteLogoX({ className }: IconProps) {
  return (
    <svg
      width='69'
      height='32'
      viewBox='0 0 69 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}>
      <path
        d='M24.185 9.39767C32.3214 17.5344 26.5588 31.4471 15.0522 31.4471H10.538V20.9833H20.9727L0 0H14.7974L24.185 9.39767Z'
        fill='currentColor'
      />
      <path
        d='M30.4676 22.0545C22.3312 13.9177 28.0937 0.00503237 39.6004 0.00503338L68.1917 0.00503258V10.4688H33.6799L54.6578 31.447H39.8605L30.4676 22.0545Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default WhiteLogoX;
